import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FreeSpace from "./style/FreeSpace";
import Button from "./style/Button";
import Breadcrumbs from "./style/Breadcrumbs";
import Link from "./style/Link";
import { useAPI } from "../api/API";
import { endOfDay, startOfDay } from "date-fns";

const LongateLicense = () => {
    const params = useParams();
    const api = useAPI();
    const [msg, setMsg] = useState("");

    const [login, setLogin] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [organizationId, setOrganizationId] = useState(0);

    const navigate = useNavigate();
    const [expDate, setExpDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());

    useEffect(() => {
        const getLicense = async (id) => {
            try {
                const data = await api.getLicense({ id });

                setLogin(data.login);
                setOrganizationName(data.organization);
                setOrganizationId(data.organization_id);
            } catch (error) {
                console.log(error);
            }
        };

        getLicense(params.lic_id);
    }, [params, api]);

    const renewKeys = async () => {
        try {
            const startDateFixed = startOfDay(startDate);
            const expDateFixed = endOfDay(expDate);
            await api.renewKeys({
                id: params.lic_id,
                activate_from: startDateFixed.toISOString(),
                expires_at: expDateFixed.toISOString(),
            });
            navigate(`..`);
        } catch (error) {
            console.error(error);
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };

    return (
        <>
            <Breadcrumbs fullwidth>
                <Link to="/dashboard">Главная</Link>
                <Link to="/organizations">Организации</Link>
                <Link
                    to={`/organization/${organizationId}?id=${organizationId}`}
                >
                    {organizationName}
                </Link>
                <Link to={`..`}>{login}</Link>
                <Link to="#" aria-current="page">
                    Продлить ключи
                </Link>
            </Breadcrumbs>
            <p className="has-text-centered has-text-danger">{msg}</p>
            <table className="table is-fullwidth">
                <thead>
                    <tr>
                        <th>Дата активации</th>
                        <th>Дата истечения</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <DatePicker
                                className="input"
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                dateFormat="dd/MM/yyyy"
                            />
                        </td>
                        <td>
                            <DatePicker
                                className="input"
                                selected={expDate}
                                onChange={(date) => setExpDate(date)}
                                dateFormat="dd/MM/yyyy"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>

            <Button withBorders onClick={renewKeys}>
                Изменить даты
            </Button>

            <FreeSpace />
        </>
    );
};

export default LongateLicense;
