const productCodes = [
    {
        name: "Квадросим",
        value: "001",
        url: (active) => `/qs-button-${active ? "enabled" : "disabled"}.svg`,
    },
    {
        name: "Сборкасим",
        value: "002",
        url: (active) => `/ss-button-${active ? "enabled" : "disabled"}.svg`,
    },
];

const ProductSwitch = (props) => (
    <>
        {productCodes.map((code) => (
            <img
                key={code.value}
                src={code.url(code.value === props.productCode)}
                alt={code.name}
                onClick={() => props.setProductCode(code.value)}
            />
        ))}
    </>
);

export default ProductSwitch;
